.App {
  text-align: center;
  padding: 20px 20px 0 20px;
}

.text-left {
  padding-left: 16px;
  text-align: left;
}

#root {
  /* height: 100vh; 
  height: calc(var(--vh, 1vh) * 100); */
  height: 100vh;  
  max-height: -webkit-fill-available;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.rich-text-input-tis .ql-container.ql-snow {
  margin-top: 3px;
  padding: 5px;
  border-radius: 8px;
  border: none;
  background: #fff;
}
